import {create, createStore} from "zustand";
import {createDmtConfiguratorSlice} from "./createDmtConfiguratorSlice";
import {createApplicationSlice} from "./createApplicationSlice";
import models from "../configurations/models";
import defaultSizes from "../configurations/defaultSizes";
import sizeGroup from "../configurations/sizeGroup";

export default function storeCreator({
  modelId= 'kr0',
  sizes= defaultSizes,
  materials= [],
  lang= 'en',
  ...props
}) {

  const configurableGroups = [
    ...models[modelId].configurableGroups, {
      ...sizeGroup,
      configurations: sizes.map((size, idx) => ({
          id: `${idx}-${size}`,
          name: size,
        })
      )
    }
  ];


  //Init 3d models with this materials configuration
  const currentMaterials = materials.reduce( (acc, current, idx, arr) => {
    const group = models[modelId].configurableGroups.find( group => group.name === current.groupName);
    let result = [...acc];
    if(group !== undefined) {
      const configuration = group.configurations.find( configuration => configuration.name === current.configuration.name);
      if(configuration !== undefined) {
        result = [...result, {
          group,
          configuration,
        }];
      }
    }
    return result;
  }, []);

  return createStore( (state)  => ({
    ...createDmtConfiguratorSlice(state),
    ...createApplicationSlice(state),
    sizes,
    modelConfiguration: {
      ...models[modelId],
      configurableGroups,
    },
    currentMaterials,
    lang,
    ...props,
  }));

}
