import {useEffect} from "react";
import useAppStore from "./useAppStore";

const MATERIAL_SELECTORS = ['color'];

export default function useSetMaterial(viewer, loaded) {

  const {currentMaterials, toggleApplyingMaterials} = useAppStore( (state) => ({
    toggleApplyingMaterials: state.toggleApplyingMaterials,
    currentMaterials: state.currentMaterials,
  }));

  useEffect(function (){
    if(viewer === null) return;
    if(loaded === false) return;

    if(currentMaterials.length === 0) {
      toggleApplyingMaterials();
      viewer.resetMaterials()
        .then(data => console.log(data))
        .error(err => console.error(err));
      toggleApplyingMaterials();
      return;
    }

    toggleApplyingMaterials();

    const filteredCurrentMaterial = currentMaterials.filter(material => MATERIAL_SELECTORS.indexOf(material.group.selectorType) >= 0);

    viewer.setMaterialsGroups({
      configurations: filteredCurrentMaterial.map(material => ({
        configurationName: material.configuration.name,
        groupName: material.group.name,
      }))
    })
      .then( data => console.log(data))
      .error( err => console.warn(err));

    toggleApplyingMaterials();
  }, [viewer, loaded, currentMaterials]);

}
