import * as styles from "../templates/MainTemplate.module.css";
import Inline from "../atoms/Inline";
import Label from "../atoms/Label";
import useAppStore from "../hooks/useAppStore";

export default function ProductInfo({
  name,
  price,
  ...props
}) {

  const {showRecap, modelConfiguration, showARModal} = useAppStore( (state) => ({
    showRecap: state.showRecap,
    modelConfiguration: state.modelConfiguration,
    showARModal: state.showARModal,
  }));

  const productInfoClassName = showARModal ? `${styles.productInfo} ${styles.hide}` : `${styles.productInfo}`;

  return (
    showRecap === false && (
      <div className={productInfoClassName} >
        <Inline>
          <Label size="large" weight="medium">{name}</Label>
          <Label size="large" weight="medium" color='#ff7500'>{price}</Label>
        </Inline>
      </div>
    )
  );
}
