import React, {Children} from "react";
import * as styles from "./Inline.module.css";

export default function Inline({
  align = 'start',
  verticalAlign = 'center',
  gap = 'normal',
  reverse = false,
  children,
}) {

  let className = `${styles[`halign-${align}`]} ${styles[`valign-${verticalAlign}`]} ${styles[`gap-${gap}`]}`
  if (reverse === true) className = `${className} ${styles.reverse}`;

  return (
    <div className={className}>
      {Children.map(children, child => (
        <>
          {child}
        </>
      ))}
    </div>
  )
}
