import * as styles from "./Modal.module.css"
import Stack from "../atoms/Stack";
import Label from "../atoms/Label";
import Inline from "../atoms/Inline";
import Button from "../atoms/Button";
import CloseIcon from "../icons/CloseIcon";
import {useTranslation} from "react-i18next";

export default function Modal({
  title,
  message,
  isOpen,
  onConfirm,
  onCancel,
  onClose,
  ...props
}){

  const { t } = useTranslation();

  const containerClassName = `${styles.container} ${isOpen ? styles.containerOpened : null}`;
  const overlayClassName = `${styles.overlay} ${isOpen ? styles.overlayOpened : null}`;
  const modalClassName = `${styles.modal} ${isOpen ? styles.modalOpened : null}`;

  return (
      <div className={containerClassName}>
        <div className={overlayClassName}/>
        <div className={modalClassName}>
          <button className={styles.buttonClose} onClick={onClose}>
            <CloseIcon/>
          </button>
            <div className={styles.modalContent}>
              <Stack valign="space-between" halign="center" gap="large">
                <Label className={styles.title}>{title}</Label>
                <span className={styles.message}>{message}</span>
                <Inline align="space-between">
                  <Button primary={false} onClick={onCancel}>{t('button.cancel')}</Button>
                  <Button onClick={onConfirm}>{t('button.confirm')}</Button>
                </Inline>
              </Stack>
            </div>
        </div>
      </div>
    );

}
