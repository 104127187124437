export const createApplicationSlice = (set) => ({
  theme: 'dark',
  menuOpened: false,
  resetModalOpened: false,
  showRecap: false,
  showARModal: false,

  toggleMenu: () => set(state => ({
    menuOpened: !state.menuOpened
  })),
  toggleTheme: () => set(state => ({
    theme: state.theme === 'dark' ? 'light' : 'dark'
  })),
  toggleResetModal: () => set(state => ({
    resetModalOpened: !state.resetModalOpened
  })),
  applyingMaterials: false,
  toggleApplyingMaterials: () => set(state => ({
    applyingMaterials: !state.applyingMaterials
  })),

  toggleShowRecap: () => set(state => ({
    showRecap: !state.showRecap
  })),

  toggleShowARModal: () => set(state => ({
    showARModal: !state.showARModal
  })),
  currentGroup: null,
  setCurrentGroup: (group) => set({ currentGroup: group}),
});
