{
  "button": {
    "back": "indietro",
    "cancel": "annulla",
    "next": "avanti",
    "confirm": "prosegui",
    "virtualTryOn": "Try it next to you",
    "changeTheme": "Cambia sfondo",
    "reset": "reset",
    "purchase": "acquista",
    "backToConfigurator": "torna al configuratore",
    "configurator": "configuratore"
  },
  "text": {
    "notice": "attenzione",
    "reset": "EFFETTUANDO IL RESET VERRANNO CANCELLATE LE CONFIGURAZIONI SIN QUI SALVATE"
  },
  "label": {
    "bianco": "white",
    "nero": "black",
    "giallo": "yellow",
    "grigio": "grey",
    "coral": "coral",
    "rosso": "red",
    "blu": "blue",
    "arancio": "orange",
    "orange": "orange",
    "antracite": "anthracite",
    "boanero": "gunmetal",
    "rame": "copper",
    "argento": "silver",
    "celestino": "ocean",
    "azzurro": "sky blue",
    "verde": "green",
    "mattone": "brick",
    "lilla": "soft violet",
    "rosa": "pink fluo"
  },
  "material": {
    "linguetta": "lingua",
    "knit": "calza",
    "skin": "skin",
    "retro banda": "tallone",
    "retro logo": "logo tallone",
    "passacavi": "passacavi",
    "boa": "boa",
    "lacci": "lacci",
    "taglia": "taglia"
  }
}
