.container {
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  transition: all 200ms ease-in;
  text-transform: uppercase;
}

.container:hover {
  overflow-x: auto;
}

.fadeout {
  opacity: 0;
}

.container span {
  text-transform: uppercase;
}

.circleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.81rem;
  height: 2.81rem;
  border: 1px solid transparent;
  border-radius: 50%;
  box-sizing: border-box;
  transition: all 100ms ease-in;
  text-transform: uppercase;
}

.circleContainer:hover {
  border: 1px solid #ff7500;
}

.selected {
  composes: circleContainer;
  border: 1px solid #ff7500;
}

.container button {
  color: var(--main-container-foreground-color);
}
