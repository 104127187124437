import kr0 from "../configurations/kr0.js";

export const createDmtConfiguratorSlice = (set) => ({
  /* example [{
    group : { id: 25, name: "LINGUETTA", label: "Linguetta", selectorType: 'color'},
    configuration: { id: 83, name: "Nero", color: "rgb(0, 0, 0)"}
  }]*/
  currentMaterials: [],
  setCurrentMaterials: (items) => set({currentMaterials: items}),
});
