import linguetta from "./camera/linguetta.json";
import knit from "./camera/knit.json";
import skin from "./camera/skin.json";
import retrologo from "./camera/retro-logo.json";
import retrobanda from "./camera/retro-banda.json";
import lacci from "./camera/lacci.json";
import boa from "./camera/boa.json";
import passacavi from "./camera/passacavi.json";

export default {
  name: "KR0",
  price: "380€",
  id: "XPJB7Z33J9",
  configurableGroups: [
    {
      id: 25,
      name: "LINGUETTA",
      label: "Linguetta",
      selectorType: "color",
      camera: linguetta,
      configurations: [
        {
          id: 82,
          name: "Bianco",
          color: "rgb(255, 255, 255)",
          code: 'KNI201',
        },
        {
          id: 83,
          name: "Nero",
          color: "rgb(0, 0, 0)",
          code: 'KNI202',
        },
        {
          id: 84,
          name: "Grigio",
          color: "rgb(146, 146, 146)",
          code: 'KNI203',
        },
        {
          id: 85,
          name: "Blu",
          color: "rgb(0, 116, 216)",
          code: 'KNI205',
        },
        {
          id: 86,
          name: "Rosso",
          color: "rgb(185, 10, 29)",
          code: 'KNI204',
        },
        {
          id: 128,
          name: "Celestino",
          color: "#b8d9e3",
          code: 'KNI206',
        },
        {
          id: 124,
          name: "Azzurro",
          color: "#0085a6",
          code: 'KNI207',
        },
        {
          id: 123,
          name: "Mattone",
          color: "#b34024",
          code: 'KNI208',
        }, 
        {
          id: 125,
          name: "Rosa",
          color: "#ff5b7d",
          code: 'KNI209',
        },
        {
          id: 126,
          name: "Lilla",
          color: "#a99dd0",
          code: 'KNI210',
        },
      ]
    },
    {
      id: 22,
      name: "KNIT",
      label: "KNIT",
      selectorType: "color",
      camera: knit,
      configurations: [
        {
          id: 116,
          name: "Bianco",
          color: "rgb(255, 255, 255)",
          code: 'KNI101',
        },
        {
          id: 117,
          name: "Nero",
          color: "rgb(0, 0, 0)",
          code: 'KNI102',
        },
        {
          id: 118,
          name: "Grigio",
          color: "rgb(170, 170, 170)",
          code: 'KNI103',
        },
        {
          id: 119,
          name: "Giallo",
          color: "rgb(231, 186, 52)",
          code: 'KNI107',
        },
        {
          id: 120,
          name: "Coral",
          color: "rgb(250, 93, 72)",
          code: 'KNI106',
        },
        {
          id: 121,
          name: "Rosso",
          color: "rgb(185, 10, 29)",
          code: 'KNI104',
        },
        {
          id: 122,
          name: "Blu",
          color: "rgb(0, 60, 130)",
          code: 'KNI105',
        },
        {
          id: 128,
          name: "Celestino",
          color: "#b8d9e3",
          code: 'KNI108',
        },
        {
          id: 124,
          name: "Azzurro",
          color: "#0085a6",
          code: 'KNI109',
        },
        {
          id: 123,
          name: "Mattone",
          color: "#b34024",
          code: 'KNI110',
        }, {
          id: 125,
          name: "Rosa",
          color: "#ff5b7d",
          code: 'KNI111',
        },
        {
          id: 126,
          name: "Lilla",
          color: "#a99dd0",
          code: 'KNI112',
        },
      ]
    },
    {
      id: 23,
      name: "SKIN",
      label: "SKIN",
      selectorType: "color",
      camera: skin,
      configurations: [
        {
          id: 123,
          name: "Bianco",
          color: "rgb(255, 255, 255)",
          code: 'SKI001',
        },
        {
          id: 124,
          name: "Nero",
          color: "rgb(0, 0, 0)",
          code: 'SKI002',
        },
        {
          id: 125,
          name: "Grigio",
          color: "rgb(122, 122, 122)",
          code: 'SKI003',
        },
        {
          id: 126,
          name: "Blu",
          color: "rgb(0, 116, 216)",
          code: 'SKI005',
        },
        {
          id: 127,
          name: "Rosso",
          color: "rgb(185, 10, 29)",
          code: 'SKI004',
        }
      ]
    },
    {
      id: 33,
      name: "RETRO BANDA",
      label: "Foxing",
      selectorType: "color",
      camera: retrobanda,
      configurations: [
        {
          id: 153,
          name: "Arancio",
          color: "rgb(255, 115, 0)",
          code: 'FOX006',
        },
        {
          id: 154,
          name: "Bianco",
          color: "rgb(255, 255, 255)",
          code: 'FOX001',
        },
        {
          id: 155,
          name: "Nero",
          color: "rgb(0, 0, 0)",
          code: 'FOX002',
        },
        {
          id: 156,
          name: "Rosso",
          color: "rgb(185, 10, 29)",
          code: 'FOX004',
        },
        {
          id: 157,
          name: "Blu",
          color: "rgb(0, 116, 216)",
          code: 'FOX005',
        },
        {
          id: 158,
          name: "Grigio",
          color: "rgb(122, 122, 122)",
          code: 'FOX003',
        }
      ]
    },
    {
      id: 34,
      name: "RETRO LOGO",
      label: "Logo Foxing",
      selectorType: "color",
      camera: retrologo,
      configurations: [
        {
          id: 159,
          name: "BIANCO",
          color: "rgb(255, 255, 255)",
          code: 'TXL001',
        },
        {
          id: 160,
          name: "Antracite",
          color: "rgb(0, 0, 0)",
          code: 'TXL002',
        },
        {
          id: 161,
          name: "ORANGE",
          color: "rgb(255, 115, 0)",
          code: 'TXL003',
        }
      ]
    },
    {
      id: 26,
      name: "PASSACAVI",
      label: "Passacavi",
      selectorType: "color",
      camera: passacavi,
      configurations: [
        {
          id: 87,
          name: "Nero",
          color: "rgb(0, 0, 0)",
          code: 'ACP002',
        },
        {
          id: 88,
          name: "Grigio",
          color: "rgb(170, 170, 170)",
          code: 'ACP003',
        },
        {
          id: 89,
          name: "Blu",
          color: "rgb(0, 116, 216)",
          code: 'ACP005',
        },
        {
          id: 90,
          name: "Rosso",
          color: "rgb(185, 10, 29)",
          code: 'ACP004',
        },
        {
          id: 152,
          name: "Bianco",
          color: "rgb(255, 255, 255)",
          code: 'ACP001',
        }
      ]
    },
    {
      id: 32,
      name: "BOA",
      label: "Boa",
      selectorType: "color",
      camera: boa,
      configurations: [
        {
          id: 134,
          name: "Bianco",
          color: "rgb(255, 255, 255)",
          code: 'BOA001',
        },
        {
          id: 135,
          name: 'boanero',
          color: "rgb(0, 0, 0)",
          code: 'BOA002',
        },
        {
          id: 136,
          name: "Argento",
          color: "rgb(210, 210, 216)",
          code: 'BOA003',
        },
        {
          id: 137,
          name: "Blu",
          color: "rgb(32, 112, 183)",
          code: 'BOA005',
        },
        {
          id: 138,
          name: "Rosso",
          color: "rgb(160, 0, 50)",
          code: 'BOA004',
        },
        {
          id: 139,
          name: "Rame",
          color: "rgb(176 141 87)",
          code: 'BOA006',
        },
      ]
    },
    {
      id: 30,
      name: "LACCI",
      label: "Lacci",
      selectorType: "color",
      camera: lacci,
      configurations: [
        {
          id: 108,
          name: "Bianco",
          color: "rgb(255, 255, 255)",
          code: 'ACL001',
        },
        {
          id: 109,
          name: "Nero",
          color: "rgb(0, 0, 0)",
          code: 'ACL002',
        },
        {
          id: 110,
          name: "Grigio",
          color: "rgb(129, 128, 127)",
          code: 'ACL003',
        }
      ]
    }
  ]
}
