import Inline from "../atoms/Inline";
import * as styles from "./SizeSelector.module.css";
import Label from "../atoms/Label";

export default function SizeSelector({
 items,
 selected,
 disabled= false,
 onSelect,
}) {

  const sizeContainerClassName = (size) => `${styles.sizeContainer} ${selected && size.id === selected.id ? styles.selected : null}`;

  return (
    <div className={styles.container}>
      <Inline gap="large" verticalAlign="center">
        {items.map( (size, idx) => (
          <button key={idx} onClick={ () => onSelect(size) } disabled={disabled}>
              <div className={sizeContainerClassName(size)}>
                <Label size="medium" weight="normal">{size.name}</Label>
              </div>
          </button>
        ))}
      </Inline>
    </div>
  );

}
