import {useRef} from "react";
import storeCreator from "./stores/storeCreator";
import StoreContext from "./contexts/StoreContext";

export default function StoreProvider({
  options,
  children,
  ...props
}) {

  const storeRef = useRef();

  if (!storeRef.current) {
    storeRef.current = storeCreator({
      ...options,
      ...props
    });
  }

  return (
    <StoreContext.Provider value={storeRef.current}>
      {children}
    </StoreContext.Provider>
  )

}
