import useAppStore from "../hooks/useAppStore";
import ColorSelector from "../molecules/ColorSelector";
import SizeSelector from "../molecules/SizeSelector";

export default function VariantSelector({
  ...props
}) {

  const {modelConfiguration, currentGroup, currentMaterials, setCurrentMaterials, applyingMaterials} = useAppStore( (state) => ({
    modelConfiguration: state.modelConfiguration,
    currentGroup: state.currentGroup,
    setCurrentMaterials: state.setCurrentMaterials,
    currentMaterials: state.currentMaterials,
    applyingMaterials: state.applyingMaterials,
  }));

  if(currentGroup === null) return null;

  const material = modelConfiguration.configurableGroups.find( group => group.id === currentGroup.id);

  const selection = (selectedMaterial) => {
    return currentMaterials
      .filter( material => material.group.id !== selectedMaterial.group.id)
      .concat(selectedMaterial);
  };

  const getSelected = () => {
    const currentMaterial = currentMaterials.find( material => material.group.id === currentGroup.id);
    const configuration =  currentMaterial !== undefined ? currentMaterial.configuration : null;

    return configuration && material.configurations.find( item => item.id === configuration.id);
  }

  switch (currentGroup.selectorType) {

    case 'color':
      return (
        <ColorSelector
          items={material.configurations}
          onSelect={item => {
            setCurrentMaterials(
              selection({
                group: currentGroup,
                configuration: item,
              })
            )}}
          selected={getSelected()}
          disabled={applyingMaterials}
        />
      );

    case 'size':
      return (
        <SizeSelector
          items={material.configurations}
          onSelect={item => {
            setCurrentMaterials(
              selection({
                group: currentGroup,
                configuration: item,
              })
            )
          }}
          selected={getSelected()}
          disabled={applyingMaterials}
        />
      );

    default:
      console.error(`unknown group ${currentGroup}`);
      return null;
  }

}
