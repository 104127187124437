export default {

  light: {

    '--main-container-background-color': 'radial-gradient(circle, #fff, #e4e4e4)',
    '--main-container-foreground-color': '#000',

    '--menu-footer-background-color': '#c8c8c8',
    '--menu-footer-foreground-color': '#000',

    '--progress-container-background-color': 'rgba(216, 216, 216, 0.35)',
    '--progress-color': '#000000',

    // buttons
    '--button-primary-background-color': '#000',
    '--button-primary-text-color': '#fff',

    '--button-secondary-background-color': '#fff',
    '--button-secondary-text-color': '#000',

    '--button-purchase-background--color': '#ff7500',
    '--button-purchase-text-color': '#fff0',


    // configuration item
    '--item-active-color': '#ff7500',
    '--item-todo-color': 'rgba(0, 0, 0, .3)',
    '--item-done-color': '#000000',
    '--configuration-item-border-bottom-color': '#dedede',

    '--textIcon-color': '#000',
    '--logo-color': '#000',
  },

  dark: {

    '--main-container-background-color': 'radial-gradient(circle, #333, #131313)',
    '--main-container-foreground-color': '#fff',

    '--menu-footer-background-color': '#000',
    '--menu-footer-foreground-color': '#fff',

    '--progress-container-background-color': 'rgba(216, 216, 216, 0.35)',
    '--progress-color': '#ffffff',

    '--button-primary-background-color': '#fff',
    '--button-primary-text-color': '#000',

    '--button-secondary-background-color': '#000',
    '--button-secondary-text-color': '#fff',

    '--button-purchase-background--color': '#ff7500',
    '--button-purchase-text-color': '#fff0',

    '--item-active-color': '#ff7500',
    '--item-todo-color': 'rgba(255, 255, 255, .19)',
    '--item-done-color': '#ffffff',
    '--configuration-item-border-bottom-color': 'rgba(255 255 255 / .21)',

    '--textIcon-color': '#fff',

    '--logo-color': '#fff',
  }

}
