{
  "button": {
    "back": "zurück",
    "cancel": "Stornieren",
    "next": "Fortfahren",
    "confirm": "Bestätigen",
    "virtualTryOn": "Try it next to you",
    "changeTheme": "Hintergrund Ändern",
    "reset": "zurücksetzen",
    "purchase": "Besorgen",
    "backToConfigurator": "Konfigurator",
    "configurator": "Konfigurator"
  },
  "text": {
    "notice": "Warnung",
    "reset": "BEI DER DURCHFÜHRUNG DES RESET WERDEN DIE BIS HIER GESPEICHERTEN KONFIGURATIONEN GELÖSCHT"
  },
  "label": {
    "bianco": "white",
    "nero": "black",
    "giallo": "yellow",
    "grigio": "grey",
    "coral": "coral",
    "rosso": "red",
    "blu": "blue",
    "arancio": "orange",
    "orange": "orange",
    "antracite": "anthracite",
    "boanero": "gunmetal",
    "rame": "copper",
    "argento": "silver",
    "celestino": "ocean",
    "azzurro": "sky blue",
    "verde": "green",
    "mattone": "brick",
    "lilla": "soft violet",
    "rosa": "pink fluo"
  },
  "material": {
    "linguetta": "ZUNGE",
    "knit": "STRICKEINSATZ",
    "skin": "skin",
    "retro banda": "FERSE",
    "retro logo": "FERSENLOGO",
    "passacavi": "FÜHRUNGEN",
    "boa": "boa",
    "lacci": "SCHNÜRSENKEL",
    "taglia": "Schuhgröße"
  }
}
